// MainPage.js
import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import AboutUs from '../components/AboutUs/AboutUs';
import Services from '../components/Services/Services';
import Testimonials from '../components/Testimonials/Testimonials';
import Team from '../components/Team/Team';
import ContactForm from '../components/ContactForm/ContactForm';
import Map from '../components/Map/Map';
import Footer from '../components/Footer/Footer';
import './styles/mainPage.css';

const MainPage = () => {
    return (
        <div className="main-page">
            <HeroSection />
            <AboutUs />
            <Services />
            <Testimonials />
            <Team />
            <ContactForm />
            <Map />
            <Footer />
        </div>
    );
};

export default MainPage;
